<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Блок жагсаалт</h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
            <router-link to="/add-block">
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus">
        <el-tab-pane label="All" name="all">
          <div class="panel-content">
            <div class="pl20 pr20 table filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'firstChar'>
                              Нэр А - Я
                           </el-dropdown-item>
                          <el-dropdown-item command = 'lastChar'>
                              Нэр Я - А
                          </el-dropdown-item>
                          <el-dropdown-item command = 'newest'>
                              Шинэ эхэнд
                          </el-dropdown-item>
                          <el-dropdown-item command = 'oldest'>
                              Хуучин эхэнд
                          </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <!-- blockTable -->
            <block-table :search='search' :loading='isLoading' :blocks='blockData' :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"/>
            <!-- blockTable -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="Active" name="active">
          <div class="panel-content">
            <div class="pl20 pr20 table filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'firstChar'>
                              Нэр А - Я
                           </el-dropdown-item>
                          <el-dropdown-item command = 'lastChar'>
                              Нэр Я - А
                          </el-dropdown-item>
                          <el-dropdown-item command = 'newest'>
                              Шинэ эхэнд
                          </el-dropdown-item>
                          <el-dropdown-item command = 'oldest'>
                              Хуучин эхэнд
                          </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <!-- blockTable -->
            <block-table :search='search' :loading='isLoading' :blocks='blockData' :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"/>
            <!-- blockTable -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="Expired" name="expired">
          <div class="panel-content">
            <div class="pl20 pr20 table filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'firstChar'>
                              Нэр А - Я
                           </el-dropdown-item>
                          <el-dropdown-item command = 'lastChar'>
                              Нэр Я - А
                          </el-dropdown-item>
                          <el-dropdown-item command = 'newest'>
                              Шинэ эхэнд
                          </el-dropdown-item>
                          <el-dropdown-item command = 'oldest'>
                              Хуучин эхэнд
                          </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <!-- blockTable -->
            <block-table :search='search' :loading='isLoading' :blocks='blockData' :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"/>
            <!-- blockTable -->
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import blockTable from './components/blockTable'
import services from '../../../helpers/services'
export default {
  components: {
    blockTable
  },

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getBlocks(from, size)
    this.isLoading = true
  },

  data () {
    return {
      activeTabStatus: 'all',
      isLoading: false,
      blockData: [],
      totalCount: 0,
      currentPage: 1,
      loading: false,
      search: '',
      pageSize: 20

    }
  },

  methods: {
    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.name_mon.localeCompare(b.name_mon))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.name_mon.localeCompare(a.name_mon))
      } else if (method === 'newest') {
        data.sort((a, b) => b.created_at.localeCompare(a.created_at))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.created_at.localeCompare(b.created_at))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.blockData = this.sortMethods(data, this.blockData)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'blockList', query: { page: this.currentPage, size: this.pageSize } })
      this.getBlocks((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'blockList', query: { page: item, size: this.pageSize } })
      this.getBlocks((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    getBlocks (from, size) {
      const query = '?search_text=' + this.search + '&from=' + from + '&size=' + size
      this.isLoading = true
      services.getBlocks(query).then(response => {
        this.blockData = response.datas
        this.totalCount = response.total
        this.isLoading = false
      })
    }
  }
}
</script>
