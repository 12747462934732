<template>
  <div>
    <el-table
    :show-header="true"
    v-loading='loading'
    :data="blocks ? blocks.filter(data => !search || data.name_mon.toLowerCase().includes(search.toLowerCase())) : null"
    @cell-click="clickCell"
    style="width: 100%">
      <el-table-column label="Гарчиг">
         <template slot-scope="scope">
           {{scope.row.name_mon}}
        </template>
      </el-table-column>
      <el-table-column label="Байрлах хуудас">
         <template slot-scope="scope">
           {{scope.row.page}}
        </template>
      </el-table-column>
      <el-table-column label="Төлөв">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'active'" type="success" effect="dark">Active</el-tag>
          <el-tag v-else-if="scope.row.status === 'expired'" type="info" effect="dark">Expired</el-tag>
          <el-tag v-else type="warning" effect="dark">{{scope.row.status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Эрэмбэ">
        <template slot-scope="scope">
          {{scope.row.sort}}
        </template>
      </el-table-column>
      <el-table-column label="Эхлэх огноо">
        <template slot-scope="scope">
          <div>{{formatDate(scope.row.start_date)}}</div>
        </template>
        </el-table-column>
        <el-table-column label="Дуусах огноо">
         <template slot-scope="scope">
          <div>{{formatDate(scope.row.end_date)}}</div>
         </template>
        </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import helper from '../../../../helpers/helper'
export default {
  name: 'BlockData',
  props: [
    'search',
    'blocks',
    'loading',
    'totalCount',
    'pageSize',
    'currentPage',
    'curentPageHandler',
    'sizeChangeHandler'
  ],

  methods: {
    formatDate (val) {
      return helper.formatDate(val, 'YYYY/MM/DD')
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'blockEdit',
          params: {
            id: row.super_block_id
          }
        }
      )
    }
  }

}
</script>
